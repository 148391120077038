import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-teacher/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { defineComponent, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
export default defineComponent({
  props: {},
  components: {},
  setup: function setup() {
    var router = useRouter();
    var dataMap = reactive({
      schoolTitle: ''
    });
    var refData = toRefs(dataMap);
    var store = useStore();
    dataMap.schoolTitle = store.getters["login/schoolTitleData"];

    function handleLogout() {
      store.dispatch('login/accountLoginOutAction');
      router.push('/login');
    }

    function handleGotoStudentList() {
      router.push('/studentList');
    }

    return _objectSpread(_objectSpread({}, refData), {}, {
      handleLogout: handleLogout,
      handleGotoStudentList: handleGotoStudentList
    });
  }
});